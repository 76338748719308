











































































































import { getUrl, OpenSocialLink } from "@/helpers";
import {
  mdiWeb,
  mdiFacebook,
  mdiInstagram,
  mdiTwitter,
  mdiYoutube,
  mdiPlus,
} from "@mdi/js";
import sanitizeHtml from "sanitize-html";
import Vue from "vue";
export default Vue.extend({
  name: "IndexFeaturedList",
  data() {
    return {
      mdiWeb,
      mdiFacebook,
      mdiInstagram,
      mdiTwitter,
      mdiYoutube,
      mdiPlus,
    };
  },
  computed: {
    parent() {
      if (!this.category) {
        return null;
      }
      return this.$store.getters["directorio/categories"].filter(
        (c) => c.id === this.category.parent
      )[0];
    },
    category() {
      return this.$store.getters["directorio/featuredCategory"];
    },
    companies() {
      return this.$store.getters["directorio/featuredCompanies"];
    },
    images() {
      return this.$store.getters["directorio/featuredImages"];
    },
  },
  methods: {
    truncateName(name: string) {
      name = name.toLocaleLowerCase();
      return name.length > 25 ? name.slice(0, 25) + "..." : name;
    },
    truncateDescription(description: string) {
      return description.length > 75
        ? description.slice(0, 70) + "..."
        : description;
    },
    getImage(companyId: string, logo_path: string) {
      const images = this.images.filter((c) => c.company_id === companyId);
      return this.getUrl(images.length > 0 ? images[0].image_path : logo_path);
    },
    getDescription(blocks) {
      const ps = blocks.blocks.filter((b) => b.type === "paragraph");
      let text = "";
      ps.forEach((p) => (text += p.data.text + " "));
      return sanitizeHtml(text, {
        allowedTags: [],
        allowedAttributes: {},
        allowedIframeHostnames: [],
      })
        .replace(/\n/g, " ")
        .trim();
    },
    getUrl,
    OpenSocialLink,
  },
});
