import { ActionTree, MutationTree, GetterTree } from "vuex";
import * as Sentry from "@sentry/browser";
import axios from "axios";

if (process.env.NODE_ENV === "production") {
  axios.defaults.baseURL = "https://nodejs-api-production-9ff9.up.railway.app/dpe";
} else {
  axios.defaults.baseURL = "http://localhost:3000/dpe";
}

import { State as RootState } from "./index";
import {
  AdvertisingItem,
  Category,
  CarouselItem,
  Event,
  ResponseEssentials,
  ResponseIndex,
  ResponseEvents,
  ResponseCalendar,
  PricingOption,
  ResponsePricing,
  SearchResults,
  Article,
  Company,
} from "./types";

interface State {
  searchResults: SearchResults;
  advertisingItems: AdvertisingItem[];
  articles: Article[];
  carouselItems: CarouselItem[];
  categories: Category[];
  indexEvents: Event[];
  calendarEvents: Event[];
  pricingOptions: PricingOption[];
  events: Event[];
  lastEventsPage: number;
  featuredCategory: Category;
  featuredCompanies: Company[];
  featuredImages: [];

  loading: boolean;
  loadingEvents: boolean;
}

const state: State = {
  searchResults: {
    search: "",
    events: [],
    companies: [],
  },
  advertisingItems: [],
  articles: [],
  carouselItems: [],
  categories: [],
  indexEvents: [],
  calendarEvents: [],
  pricingOptions: [],
  events: [],
  lastEventsPage: 1,
  featuredCategory: null,
  featuredCompanies: [],
  featuredImages: [],

  loading: false,
  loadingEvents: false,
};

const getters: GetterTree<State, RootState> = {
  searchResults: (state) => state.searchResults,
  advertisingItems: (state) => state.advertisingItems,
  carouselItems: (state) => state.carouselItems,
  articles: (state) => state.articles,
  categories: (state) => state.categories,
  indexEvents: (state) => state.indexEvents,
  calendarEvents: (state) => state.calendarEvents,
  pricingOptions: (state) => state.pricingOptions,
  featuredCategory: (state) => state.featuredCategory,
  featuredCompanies: (state) => state.featuredCompanies,
  featuredImages: (state) => state.featuredImages,
  events: (state) => state.events,
  loading: (state) => state.loading,
  loadingEvents: (state) => state.loadingEvents,
};

const mutations: MutationTree<State> = {
  SetSearch(state, payload: SearchResults) {
    state.searchResults = payload;
  },
  SetEssentials(state, payload: ResponseEssentials) {
    state.advertisingItems = payload.advertisingItems;
    state.categories = payload.categories;
  },
  SetIndex(state, payload) {
    state.featuredCategory = payload.category;
    state.featuredCompanies = payload.companies;
    state.featuredImages = payload.gallery;
  },
  SetEvents(state, payload: ResponseEvents) {
    state.events = payload.events;
  },
  SetCalendar(state, payload: ResponseCalendar) {
    state.calendarEvents = payload.events;
  },
  SetPricing(state, payload: ResponsePricing) {
    state.pricingOptions = payload.pricing;
  },

  SetLoading(state, payload: boolean) {
    state.loading = payload;
  },
  SetLoadingEvents(state, payload: boolean) {
    state.loadingEvents = payload;
  },
};

const actions: ActionTree<State, RootState> = {
  async Search(ctx, payload) {
    ctx.commit("SetLoading", true);
    try {
      const response = await axios.post("/search", payload);
      ctx.commit("SetSearch", response.data.data);
    } catch (e) {
      Sentry.captureException(e);
      ctx.commit("SetLoading", false);
      return false;
    }
    ctx.commit("SetLoading", false);
    return true;
  },
  async GetCategory(ctx, payload) {
    ctx.commit("SetLoading", true);
    try {
      const response = await axios.get(`/category/${payload}`);
      ctx.commit("SetLoading", false);
      return response.data.data;
    } catch (e) {
      Sentry.captureException(e);
      ctx.commit("SetLoading", false);
      return false;
    }
    return true;
  },
  async GetEssentials(ctx) {
    try {
      const response = await axios.get("/essentials");
      ctx.commit("SetEssentials", response.data.data);
    } catch (e) {
      Sentry.captureException(e);
      return false;
    }
    return true;
  },
  async GetIndex(ctx) {
    ctx.commit("SetLoading", true);
    try {
      const response = await axios.get("");
      ctx.commit("SetIndex", response.data.data);
    } catch (e) {
      Sentry.captureException(e);
      ctx.commit("SetLoading", false);
      return false;
    }
    ctx.commit("SetLoading", false);
    return true;
  },
  async GetEvents(ctx, page) {
    if (ctx.state.loadingEvents) {
      return;
    }
    ctx.commit("SetLoading", true);
    try {
      const response = await axios.get(`events?page=${page}`);
      ctx.commit("SetEvents", response.data.data);
    } catch (e) {
      Sentry.captureException(e);
      ctx.commit("SetLoading", false);
      return false;
    }
    ctx.commit("SetLoading", false);
    return true;
  },
  async GetEvent(ctx, id) {
    ctx.commit("SetLoading", true);
    try {
      const response = await axios.get(`events/${id}`);
      ctx.commit("SetLoading", false);
      return response.data.data.event;
    } catch (e) {
      Sentry.captureException(e);
      ctx.commit("SetLoading", false);
      return false;
    }
  },
  async GetArticle(ctx, id) {
    ctx.commit("SetLoading", true);
    try {
      const response = await axios.get(`article/${id}`);
      ctx.commit("SetLoading", false);
      return response.data.data.article;
    } catch (e) {
      Sentry.captureException(e);
      ctx.commit("SetLoading", false);
      return false;
    }
  },
  async GetCompany(ctx, id) {
    ctx.commit("SetLoading", true);
    try {
      const response = await axios.get(`company/${id}`);
      ctx.commit("SetLoading", false);
      return response.data.data.company;
    } catch (e) {
      Sentry.captureException(e);
      ctx.commit("SetLoading", false);
      return false;
    }
  },
  async GetCalendar(ctx) {
    ctx.commit("SetLoading", true);
    try {
      const response = await axios.get(`calendar`);
      ctx.commit("SetCalendar", response.data.data);
      ctx.commit("SetLoading", false);
    } catch (e) {
      Sentry.captureException(e);
      ctx.commit("SetLoading", false);
      return false;
    }
    return true;
  },
  async GetPricing(ctx) {
    ctx.commit("SetLoading", true);
    try {
      const response = await axios.get(`pricing`);
      ctx.commit("SetPricing", response.data.data);
      ctx.commit("SetLoading", false);
    } catch (e) {
      Sentry.captureException(e);
      ctx.commit("SetLoading", false);
      return false;
    }
    return true;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
