
































































import Vue from "vue";
export default Vue.extend({
  name: "Footer",
  computed: {
    year() {
      return new Date().getFullYear();
    },
  },
});
