import Vue from "vue";
import Vuex from "vuex";

import directorio from "./directorio";

Vue.use(Vuex);

export interface State {
  isAdmin: boolean;
}

export default new Vuex.Store<State>({
  modules: {
    directorio,
  },
  state: {
    isAdmin: false,
  },
  mutations: {},
  actions: {},
});
