



























import Vue from "vue";
import { getUrl } from "@/helpers";

import { mdiMagnify } from "@mdi/js";
export default Vue.extend({
  name: "Search",
  data() {
    return {
      search: "",
      mdiMagnify,
    };
  },
  computed: {
    items() {
      return this.$store.getters["directorio/carouselItems"];
    },
    loading() {
      return this.$store.getters["directorio/loading"];
    },
  },
  methods: {
    async makeSearch() {
      if (!this.search) {
        return;
      }
      if (this.$route.name !== "Search") {
        this.$router.push({ name: "Search", query: { q: this.search } });
      } else if (this.$route.query.q !== this.search) {
        this.$router.push({ name: "Search", query: { q: this.search } });
      } else if (this.$route.query.q === this.search) {
        await this.$store.dispatch("directorio/Search", {
          search: this.search,
        });
      }
      setTimeout(() => this.$vuetify.goTo("#page-search"), 500);
    },
    getUrl,
  },
});
