



































import Vue from "vue";

import AppBar from "@/components/AppBar.vue";
import AppDrawer from "@/components/AppDrawer.vue";
import GlobalSearch from "@/components/Search.vue";

import DAdvertising from "@/components/IndexAdvertising.vue";
// import CategoryList from "@/components/CategoryList.vue";
// import ArticleList from "@/components/ArticleList.vue";
import DFooter from "@/components/Footer.vue";

export default Vue.extend({
  name: "App",
  components: {
    AppBar,
    AppDrawer,
    GlobalSearch,
    DAdvertising,
    // CategoryList,
    // ArticleList,
    DFooter,
  },
  beforeCreate() {
    this.$store.dispatch("directorio/GetEssentials");
  },
  methods: {
    SetShow(show: boolean) {
      this.$refs.drawer.SetShow(show);
    },
  },
});
