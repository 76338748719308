var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"elevation-0"},[_c('v-card-title',{staticClass:"justify-center text-center",attrs:{"id":"categories","to":"#categories"}},[_c('h2',{staticClass:"event-title"},[_vm._v("¿Qué estás buscando?")])]),_c('v-card-text',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('v-list',{attrs:{"dense":""}},_vm._l((_vm.first.filter(function (c) { return c.parent === null; })),function(category){return _c('span',{key:category.id},[(
                _vm.categories.filter(function (c) { return c.parent === category.id; }).length ===
                0
              )?_c('v-list-item',{staticClass:"grey lighten-4 item",staticStyle:{"border-color":"#c4c4c4 !important"}},[_c('v-list-item-title',{domProps:{"textContent":_vm._s(category.name)}})],1):_c('v-list-group',{staticClass:"grey lighten-4 item",staticStyle:{"border-color":"#c4c4c4 !important"},attrs:{"title":category.name},scopedSlots:_vm._u([{key:"activator",fn:function(){return [_c('v-list-item-content',[_c('v-list-item-title',{domProps:{"textContent":_vm._s(category.name)}})],1)]},proxy:true}],null,true)},_vm._l((_vm.categories.filter(
                  function (c) { return c.parent === category.id; }
                )),function(children){return _c('v-list-item',{key:children.id,staticStyle:{"border-color":"#c4c4c4 !important"},attrs:{"to":{ name: 'Category', params: { id: children.id } },"title":children.name},on:{"click":function($event){_vm.setTimeout(function () { return _vm.$vuetify.goTo('#page-category'); }, 500)}}},[_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"px-6",domProps:{"textContent":_vm._s(children.name)}})],1)],1)}),1)],1)}),0)],1),_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('v-list',{attrs:{"dense":""}},_vm._l((_vm.second.filter(function (c) { return c.parent === null; })),function(category){return _c('span',{key:category.id},[(
                _vm.categories.filter(function (c) { return c.parent === category.id; }).length ===
                0
              )?_c('v-list-item',{staticClass:"grey lighten-4 item",staticStyle:{"border-color":"#c4c4c4 !important"}},[_c('v-list-item-title',{domProps:{"textContent":_vm._s(category.name)}})],1):_c('v-list-group',{staticClass:"grey lighten-4 item",staticStyle:{"border-color":"#c4c4c4 !important"},attrs:{"title":category.name},scopedSlots:_vm._u([{key:"activator",fn:function(){return [_c('v-list-item-content',[_c('v-list-item-title',{domProps:{"textContent":_vm._s(category.name)}})],1)]},proxy:true}],null,true)},_vm._l((_vm.categories.filter(
                  function (c) { return c.parent === category.id; }
                )),function(children){return _c('v-list-item',{key:children.id,staticStyle:{"border-color":"#c4c4c4 !important"},attrs:{"to":{ name: 'Category', params: { id: children.id } },"title":children.name},on:{"click":function($event){_vm.setTimeout(function () { return _vm.$vuetify.goTo('#page-category'); }, 500)}}},[_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"px-6",domProps:{"textContent":_vm._s(children.name)}})],1)],1)}),1)],1)}),0)],1),_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('v-list',{attrs:{"dense":""}},_vm._l((_vm.third.filter(function (c) { return c.parent === null; })),function(category){return _c('span',{key:category.id},[(
                _vm.categories.filter(function (c) { return c.parent === category.id; }).length ===
                0
              )?_c('v-list-item',{staticClass:"grey lighten-4 item",staticStyle:{"border-color":"#c4c4c4 !important"}},[_c('v-list-item-title',{domProps:{"textContent":_vm._s(category.name)}})],1):_c('v-list-group',{staticClass:"grey lighten-4 item",staticStyle:{"border-color":"#c4c4c4 !important"},attrs:{"title":category.name},scopedSlots:_vm._u([{key:"activator",fn:function(){return [_c('v-list-item-content',[_c('v-list-item-title',{domProps:{"textContent":_vm._s(category.name)}})],1)]},proxy:true}],null,true)},_vm._l((_vm.categories.filter(
                  function (c) { return c.parent === category.id; }
                )),function(children){return _c('v-list-item',{key:children.id,staticStyle:{"border-color":"#c4c4c4 !important"},attrs:{"to":{ name: 'Category', params: { id: children.id } },"title":children.name},on:{"click":function($event){_vm.setTimeout(function () { return _vm.$vuetify.goTo('#page-category'); }, 500)}}},[_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"px-6",domProps:{"textContent":_vm._s(children.name)}})],1)],1)}),1)],1)}),0)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }