









































































































import Vue from "vue";
import {
  mdiMenu,
  mdiMagnify,
  mdiWhatsapp,
  mdiInstagram,
  mdiFacebook,
  mdiTwitter,
} from "@mdi/js";
export default Vue.extend({
  name: "AppBar",
  data() {
    return {
      search: "",
      mdiMenu,
      mdiMagnify,
      mdiWhatsapp,
      mdiInstagram,
      mdiFacebook,
      mdiTwitter,
    };
  },
  computed: {
    loading() {
      return this.$store.getters["directorio/loading"];
    },
  },
  methods: {
    async makeSearch() {
      if (!this.search) {
        return;
      }
      if (this.$route.name !== "Search") {
        this.$router.push({ name: "Search", query: { q: this.search } });
      } else if (this.$route.query.q !== this.search) {
        this.$router.push({ name: "Search", query: { q: this.search } });
      } else if (this.$route.query.q === this.search) {
        await this.$store.dispatch("directorio/Search", {
          search: this.search,
        });
      }
    },
    setTimeout(fn, time) {
      setTimeout(fn, time);
    },
  },
});
