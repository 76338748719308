





























import {
  mdiHome,
  mdiAccountGroup,
  mdiCalendar,
  mdiHelpCircle,
  mdiClipboardListOutline,
} from "@mdi/js";
export default {
  name: "AppDrawerComponent",
  data: () => ({
    drawer: {
      mini: false,
      show: false,
    },
    routes: [
      {
        title: "Inicio",
        icon: mdiHome,
        link: { name: "Index" },
        goTo: "#page-app",
      },
      {
        title: "Nosotros",
        icon: mdiAccountGroup,
        link: { name: "About" },
        goTo: "#page-about",
      },
      {
        title: "Por qué pautar",
        icon: mdiHelpCircle,
        link: { name: "Why" },
        goTo: "#page-why",
      },
      {
        title: "Tarifas",
        icon: mdiClipboardListOutline,
        link: { name: "Pricing" },
        goTo: "#page-pricing",
      },
      // {
      //   title: "Eventos",
      //   icon: mdiAccountGroup,
      //   link: { name: "Events" },
      // },
      // {
      //   title: "Calendario",
      //   icon: mdiCalendar,
      //   link: { name: "Calendar" },
      // },
    ],
  }),
  methods: {
    SetShow(show: boolean) {
      this.drawer.show = show;
    },
    setTimeout(fn, time) {
      setTimeout(fn, time);
    },
  },
};
