















import Vue from "vue";

import IndexCategoryList from "@/components/IndexCategoryList.vue";
import IndexFeaturedList from "@/components/IndexFeaturedList.vue";

export default Vue.extend({
  components: {
    IndexCategoryList,
    IndexFeaturedList,
  },
  created() {
    this.$store.dispatch("directorio/GetIndex");
  },
  data() {
    return {};
  },
});
