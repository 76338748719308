




































import Vue from "vue";
import { getUrl } from "@/helpers";
import { AdvertisingItem } from "../store/types";
export default Vue.extend({
  name: "Advertising",
  mounted() {
    this.blockWidth = document.getElementById("advertising-block").offsetWidth;
  },
  data() {
    return {
      blockWidth: 0,
    };
  },
  computed: {
    allItems(): AdvertisingItem[] {
      const allItems = this.$store.getters["directorio/advertisingItems"];
      const items = [...allItems];
      items.push(...allItems);
      return items;
    },
    count(): number {
      return this.$store.getters["directorio/advertisingItems"].length;
    },
    cssVars() {
      const visible = this.blockWidth / 150;
      let negativeWidth = 0;

      if (this.count * 150 > this.blockWidth) {
        // this.count * 150 -> Pixeles de todos los logos
        // (this.count / visible) -> Sacamos la cuenta de los logos que quedan por fuera del frame
        // (los que faltan terminar de rotar para empezar de nuevo el ciclo)
        // * (150 - 20) se convierte a pixeles y se le resta 20 por el padding (10 de cada lado)
        negativeWidth = this.count * 150 + (this.count / visible) * (150 - 20);
      } else {
        negativeWidth = this.count * 150 + (this.count / visible) * (150 - 20);
      }
      return {
        "--visible": `${visible}`,
        "--number-of-logos": `${this.count}`,
        "--animation-speed": `${(negativeWidth / 150) * 2}s`,
        "--negative-animation-width": `-${negativeWidth}px`,
        "--animation-width": `${this.count * 150}px`,
      };
    },
  },
  methods: {
    getUrl,
    NavigateUrl(adver) {
      if (adver.website) {
        if (adver.website.includes("http")) {
          return adver.website;
        } else {
          return `https://${adver.website}`;
        }
      }
      return "#";
    },
    Navigate(adver: AdvertisingItem) {
      if (adver.website) {
        if (adver.website.includes("http")) {
          window.open(adver.website, "_blank");
        } else {
          window.open(`https://${adver.website}`, "_blank");
        }
      } else {
        if (
          this.$route.name === "Company" &&
          this.$route.params.id === adver.id
        ) {
          return;
        }
        this.$router.push({ name: "Company", params: { id: adver.id } });
      }
    },
  },
});
