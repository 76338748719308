import Vue from "vue";
import * as Sentry from "@sentry/browser";
import * as Integrations from "@sentry/integrations";

Sentry.init({
  dsn:
    "https://e712af2ce24946caaea19295a481c2af@o266639.ingest.sentry.io/5269169",
  integrations: [
    new Integrations.Vue({ Vue, attachProps: true, logErrors: true }),
  ],
});
