

















































































































































































































































import Vue from "vue";
export default Vue.extend({
  name: "IndexCategoryList",
  computed: {
    categories() {
      return this.$store.getters["directorio/categories"];
    },
    first() {
      const roots = this.categories.filter((c) => c.parent === null);
      return roots.slice(0, roots.length / 3);
    },
    second() {
      const roots = this.categories.filter((c) => c.parent === null);
      return roots.slice(roots.length / 3, (roots.length / 3) * 2);
    },
    third() {
      const roots = this.categories.filter((c) => c.parent === null);
      return roots.slice((roots.length / 3) * 2, roots.length - 1);
    },
  },
  methods: {
    setTimeout(fn, time) {
      setTimeout(fn, time);
    },
  },
});
