import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
import Index from "../views/Index.vue";

Vue.use(VueRouter);

const routes: RouteConfig[] = [
  {
    path: "/eventos",
    name: "Events",
    component: () =>
      import(/* webpackChunkName: "events" */ "../views/Events.vue"),
  },
  {
    path: "/evento/:id",
    name: "Event",
    component: () =>
      import(/* webpackChunkName: "event" */ "../views/Event.vue"),
  },
  {
    path: "/categoria/:id",
    name: "Category",
    component: () =>
      import(/* webpackChunkName: "category" */ "../views/Category.vue"),
  },
  {
    path: "/empresa/:id",
    name: "Company",
    component: () =>
      import(/* webpackChunkName: "company" */ "../views/Company.vue"),
  },
  {
    path: "/calendario",
    name: "Calendar",
    component: () =>
      import(/* webpackChunkName: "calendar" */ "../views/Calendar.vue"),
  },
  {
    path: "/por-que",
    name: "Why",
    component: () => import(/* webpackChunkName: "why" */ "../views/Why.vue"),
  },
  {
    path: "/tarifas",
    name: "Pricing",
    component: () =>
      import(/* webpackChunkName: "pricing" */ "../views/Pricing.vue"),
  },
  {
    path: "/nosotros",
    name: "About",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/About.vue"),
  },
  {
    path: "/buscar",
    name: "Search",
    component: () =>
      import(/* webpackChunkName: "search" */ "../views/Search.vue"),
  },
  {
    path: "/articulo/:id",
    name: "Article",
    component: () =>
      import(/* webpackChunkName: "search" */ "../views/Article.vue"),
  },
  {
    path: "",
    name: "Index",
    component: Index,
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
